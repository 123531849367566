import { getPageURL, ROUTE_CONFIG } from 'services/hostPortal.service';
import { isAuthenticated } from 'services/identity.service';
import isProtectedRoute from 'services/security.service';

const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ router, children }) => {
  if (isBrowser()) {
    if (!isAuthenticated() && isProtectedRoute(router.pathname)) {
      router.replace(
        getPageURL({
          pageName: ROUTE_CONFIG.LOGIN.label,
          queryParams: {
            redirectTo: router.asPath
          }
        })
      );
    }
  }
  return children;
};

export default WithAuth;
